<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <h1>Loan amount from </h1>
        <h1><span>2,000-500,000</span></h1>
        <h4>Easy and convenient online loan for you.</h4>
        <h4>Service available 24/7</h4>
        <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/Google.png" alt="">
        </div>
      </div>
      <div class="right_item">
        <!-- <img src="@/assets/images/banner01.png" alt=""> -->
      </div>
    </div>
    <div class="inner inner2">
      <h3>Why trust Eagle Cash</h3>
      <div class="inner5_item">
        <div v-for="(item,index) in leftItemList" :key="index" class="content">
          <img :src="item.img" alt="">
          <div class="item_info">
            <!-- <h4>{{ item.title }}</h4> -->
            <p>{{ item.txt }}</p>
            <p>{{ item.txt2 ? item.txt2 : `&nbsp;` }}</p>
          </div>
        </div>
      </div>
    </div>
    <div id="selector1" class="inner inner3">
      <h3>The loan process</h3>
      <img src="@/assets/images/inner2_bg.png" alt="">
      <div class="inner5_item">
        <div v-for="(item,index) in leftItemList2" :key="index" class="content">
          <div class="item_info">
            <h2>{{ item.title }}</h2>
            <p>{{ item.txt }}</p>
            <p>{{ item.txt2 }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner4">
      <div class="title">
        <h3>Here’s what customers say</h3>
        <div>
          <img src="@/assets/images/star.png">
          <p>4.5</p>
        </div>
      </div>
      <div class="inner5_item">
        <div v-for="(item,index) in inner6ItemLeftList" :key="index" class="content">
          <div class="flex ai-c" :style="{width:'100%' , marginBottom:'20px'}">
            <img :src="item.img" alt="">
            <p :style="{marginLeft:'20px' ,fontWeight:'bold'}">{{ item.title }}</p>
          </div>
          <div class="item_info">
            <!-- <h4>{{ item.title }}</h4> -->
            <p>{{ item.txt }}</p>
            <p>{{ item.txt2 ? item.txt2 : `nbsp` }}</p>
            <p>{{ item.txt3 ? item.txt3 : `nbsp` }}</p>
          </div>
        </div>
        <div class="flex jc-c ai-c icon" :style="{background: '#FFFFFF', boxShadow:'0px 3px 20px 1px rgba(93,93,93,0.16)' , padding:'0 15px 0 20px' , borderRadius:'10px' , marginLeft:'50px'}" @click="downloadApp()">
          <img :style="{width:'20px'}" src="@/assets/images/arrow_right.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemList: [
        { title: 1, txt: 'Download the app Search “EasyCash” from the Google Play Store and download it.', img: require('@/assets/images/content01.png') },
        { title: 2, txt: 'Register Sign up with your phone number, finish some infomation in 2 minutes', img: require('@/assets/images/content02.png') },
        { title: 3, txt: 'Apply for a loan Click the “Submit” button to apply for a loan.After that, the money will be received to your bank account.', img: require('@/assets/images/content03.png') }
      ],
      leftItemList: [
        { txt: 'Get 24*7 Access to Loan Anytime,', txt2: ' Anywhere', img: require('@/assets/images/Product01.png') },
        { txt: 'Ensure your information security', img: require('@/assets/images/Product02.png') },
        { txt: '100% oline process without any ', txt2: 'mortgage', img: require('@/assets/images/Product03.png') },
        { txt: 'Excellent user service', img: require('@/assets/images/Product04.png') }
      ],
      leftItemList2: [
        { title: 'Download  app', txt: 'Download Search app “Eagle Cash”from ', txt2: 'Google Play Store' },
        { title: 'Register an account', txt: 'Register with your phone number,', txt2: 'complete some information' },
        { title: 'Fill out online forms', txt: 'It only takes 3-5 minutes to fill ', txt2: 'out the information' },
        { title: 'Submit the application', txt: 'Apply for a loan Click the “Submit”', txt2: 'button to apply for a loan' }
      ],
      inner6ItemLeftList: [
        { title: 'Danna Roberto', txt: 'Famous loan application app,the most', txt2: 'convenient way to get a loan for those who ', txt3: 'need o borrow money', img: require('@/assets/images/ng_1.png') },
        { title: 'Miller Abigail', txt: 'Very good app to help you use whenyou are ', txt2: 'in trouble, the app is easy tounderstand, ', txt3: 'everyone can submit', img: require('@/assets/images/ng_2.png') },
        { title: 'Davis Aron', txt: 'Very little information required, verysimple, ', txt2: 'hope to receive money soon,easy to apply, ', txt3: '5 stars', img: require('@/assets/images/ng_3.png') }
      ]
    }
  },
  methods: {
    downloadApp() {
      window.location.href = 'https://play.google.com/store/apps/details?id=loan.eaglecash.credit'
    }
  }
}
</script>

<style lang="scss" scoped>
  .home_top{
    min-width: 1200px;
    background: #fff;
    .inner{
      width: 100%;
      padding:50px calc((100% - 1100px) / 2);
      margin: 0 auto;
    }
    .inner1{
      // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
      background: url("~@/assets/images/loanImg/inner1_bg.png") no-repeat;
      background-size: 100% 100%;
      height: 600px;
      display: flex;
      justify-content: space-between;
      .left_item{
        box-sizing: border-box;
        padding: 90px 0px 0;
        border-radius: 10px;
        // width: 500px;
        h1{
          font-size: 45px;
          color: #333333;
          font-weight: 700;
          // margin-bottom: 10px;
          line-height: 60px;
          span{
            color: #285DFD;
          }
        }
        h4{
          font-size: 16px;
          line-height: 22px;
          color: #666666;
          font-weight: normal;
          // margin: 0px 0;
        }
        & h4:nth-of-type(1){
          margin-top: 30px;
        }
        .item_progress{
          width: 230px;
          p{
            width: 90px;
            text-align: center;
            line-height: 30px;
            border-radius: 5px;
            color: white;
            font-size: 12px;
            font-weight: bold;
            margin: 0 auto 10px;
            background: #4488EB;
          }
          img{
            width: 245px;
            height: 15px;
          }
        }
        .item_info{
          margin: 20px 0;
          display: flex;
          height: 170px;
          .info_img1{
            width: 40px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            p{
              flex: 1;
              display: flex;
              box-sizing: border-box;
              // margin-left: 10px;
              font-size: 12px;
              color: #666666;
              align-items: center;
              img{
                margin-right: 10px;
              }
            }
          }
          .info_img2{
            width: 135px;
            height: 135px;
          }
        }
        .item_download{
          cursor: pointer;
          margin-top: 60px;
          color: white;
          border-radius: 5px;
          img{
            width: 160px;
            height: 50px;
            margin-right: 5px;
          }
        }
      }
      .right_item{
        img{
          height: 376px;
          margin-right: 30px;
        }
      }
    }
    .inner2{
      h3{
        font-size: 24px;
        color: black;
        text-align: left;
        margin-bottom: 50px;
        position: relative;
        &::after{
          display: block;
          content:'';
          width: 80px;
          height: 5px;
          background: #4776FD;
          margin-top: 20px;
        }
      }
      h2{
        line-height: 30px;
        margin-bottom: 20px;
      }
      .inner5_item{
        display: flex;
        justify-content: space-between;
        margin: auto;
        .content{
          padding: 30px 10px 20px;
          background:#FFFFFF;
          color: #333333;
          width: 22%;
          border-radius: 15px;
          display: flex;
          // font-weight: 600;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          box-shadow: 0px 3px 20px 1px rgba(93,93,93,0.16);
          img{
            width: 50px;
            height: 60px;
            margin-bottom: 15px;
          }
          p{
            font-size: 13px;
            line-height: 24px;
          }
          & p:nth-of-type(1){
            padding: 10px 0 0 0;
            border-top: 1px solid #E2E2E2;
          }
        }
      }
    }
    .inner3{
      padding:50px calc((100% - 1200px) / 2);
      background: #F2F4F8;
      text-align: center;
      img{
        width: 100%;
        padding: 0 70px 0 60px;
      }
      h3{
        padding-left: 50px;
        font-size: 24px;
        color: black;
        text-align: left;
        margin-bottom: 50px;
        position: relative;
        &::after{
          display: block;
          content:'';
          width: 80px;
          height: 5px;
          background: #4776FD;
          margin-top: 20px;
        }
      }
      h2{
        line-height: 30px;
      }
      .inner5_item{
        display: flex;
        justify-content: space-between;
        margin: auto;
        .content{
          padding: 30px 10px 20px;
          color: #333333;
          flex: 1;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p{
            font-size: 13px;
            line-height: 24px;
          }
          & p:nth-of-type(1){
            padding: 10px 0 0 0;
          }
        }
      }
    }
    .inner4{
      padding-bottom: 80px;
      .title{
        display: flex;
        justify-content: space-between;
        img{
          width: 150px;
          height: 22px;
        }
        div{
          display: flex;
        }
        p{
          margin-left: 20px;
          font-size: 17px;
          color: #FFA008;
          line-height: 25px;
          font-weight: bold;
        }
      }
      h3{
        font-size: 24px;
        color: black;
        text-align: left;
        margin-bottom: 50px;
        position: relative;
        &::after{
          display: block;
          content:'';
          width: 80px;
          height: 5px;
          background: #4776FD;
          margin-top: 20px;
        }
      }
      h2{
        line-height: 30px;
        margin-bottom: 20px;
      }
      .inner5_item{
        display: flex;
        justify-content: space-between;
        margin: auto;
        .content{
          padding: 30px 20px 30px;
          background:#FFFFFF;
          color: #333333;
          width: 28%;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          box-shadow: 0px 3px 20px 1px rgba(93,93,93,0.16);
          img{
            width: 50px;
          }
          p{
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
    }
    .inner1{
      padding: 0px calc((100% - 1200px) / 2) 0;;
      height: 300px;
      position: relative;
      background-size: 100% auto;
      .left_item{
        margin: 0px 10px 10px;
        // height: 500px;
        padding: 25px 5px;
        flex: 1;
        h1{
          font-size: 24px;
          line-height: 50px;
        }
        .item_download {
          margin-top: 30px;
          img{
            display: block;
            width: 150px;
            height: 50px;
            margin: 0 auto;
          }
        }
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 100% !important;
          position: absolute;
          bottom: 0;
          margin-right: 0;
          height: 350px;
        }
      }
    }
    .inner2{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw - 40px) !important;
      margin: 0 auto !important;
      padding: 30px 20px;
      h3{
        width: 100%;
        font-size: 25px;
        text-align: left;
        margin-bottom: 20px;
      }
      .inner5_item{
        margin:  0 auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        .content{
          flex: 1;
          width: 100%;
          margin: 20px 0;
        }
      }
    }
    .inner3{
      h3{
        width: 100%;
        margin-bottom: 30px;
      }
      .inner5_item{
        display: flex;
        flex-direction: column;
      }
    }
    .inner4{
      .title{
        display: flex;
        flex-direction: column;
        padding: 0 50px;
        h3{
          font-size: 22px;
          margin-bottom: 30px;
        }
      }
      .inner5_item{
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        .content{
          width: 100%;
          margin-bottom: 20px;
        }
        .icon{
          margin-left: 0 !important;
          padding: 10px 0 !important;
        }
      }
    }
  }
}
</style>
